<template>
  <div class="configs-module">
    <div class="row col-12">
      <h1>Configs</h1>
    </div>
    <div v-if="loading">
      <b-spinner></b-spinner>
    </div>
    <div class="w-100" v-else>

      <div class="row" v-for="(items, value) in chunkedGroups" :key="value">
        <template v-for="item in items">
          <template v-for="(groupProps, groupName) in item">
            <div class="col-6 mb-2"
                 :key="groupName"
                 v-if="!notInSingleGroup(groupName) && filterByGroup(groupName).length > 0">
              <div class="card">
                <div class="card-header">
                  <h5 class="mb-0">{{ groupName | sentencePascalCase }}</h5>
                </div>
                <div class="card-body">
                  <div class="card-text">
                    <div v-for="(config, index) in filterByGroup(groupName)" :key="index">
                      <DynamicInput :type="config.type"
                                    v-model="config.value"
                                    :inputKey="config.key"
                                    :label="config.label">
                      </DynamicInput>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>

      <div class="row mt-6" v-for="(other, key) in singleGroups" :key="key">
        <div class="col-sm groups-content">
          <div :id="'accordion-' + key">
            <div class="card">
              <div class="card-header" :id="'heading' + key">
                <h5 class="mb-0" data-toggle="collapse" :data-target="'#collapse' + key" aria-expanded="false"
                    :aria-controls="'collapse' + key">
                  <feather type="arrow-down-circle" class="align-middle"></feather>
                  <span class="align-middle"> {{ other | sentencePascalCase }}</span>
                </h5>
              </div>

              <div :id="'collapse' + key" class="collapse" :aria-labelledby="'heading' + key"
                   :data-parent="'#accordion-' + key">
                <div class="card-body">
                  <div v-for="(config, index) in filterByGroup(other)" :key="index">
                    <DynamicInput :type="config.type"
                                  v-model="config.value"
                                  :inputKey="config.key"
                                  :label="config.label">
                    </DynamicInput>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CapacityLevels></CapacityLevels>
      <FloatingButtons :buttons="buttons" v-on:floating-button-click="handleFloatingButtonClick"></FloatingButtons>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.row {
  margin: 10px 0px 10px 0px;
}
.capacity-level {
  max-width: 5rem;
}
</style>

<script>
import { mapGetters, mapState } from 'vuex';
import DynamicInput from '@/components/DynamicInput.vue';
import FloatingButtons from '@/components/FloatingButtons.vue';
import CapacityLevels from './CapacityLevels.vue';

export default {
  data() {
    return {
      loading: false,
      currentConfigs: {},
      groupsPerRow: 2,
      computerVisionKeysConfig: [
        'lower_limb_computer_vision_enabled',
        'lower_limb_optical_kit_population',
        'neck_optical_kit_population',
        'upper_limb_optical_kit_population',
        'wrist_hand_optical_kit_population',
        'low_back_optical_kit_population',
      ],
      single_groups: [
        'central_systems_management',
        'support_communications_configs',
        'onboarding',
        'performance_emails',
        'validation',
        'others',
      ],
      groups: {
        features_toggle: [
          'show_prescription_recommendation_popup',
          'has_chosen_recommendations',
          'show_manual_exercise_order_helper',
          'hide_weight_prescription_popup',
          'prescription_series_enabled',
          'patient_notes_active',
          'greetings_log_enabled',
          'with_weight_enabled',
          'therapist_chat_enabled',
          'show_clinical_forms',
          'show_ai_prescription_suggestions',
          'check_pt_regulation_status',
          'bypass_pt_regulation_status',
          'self_service_rescheduling_page',
          'automatic_reassessments',
          'nps_email',
          'default_value_automatic_reassessments',
          'add_v2_multi_providers_toggle',
          'identity_check_enabled',
          'use_onboarding_capacity_checking',
          'use_dt_viable_exercises',
          'use_prescription_replacement_exercises',
        ],
        slack: [
          'slack_channel_nps_feed',
          'slack_channel_nps_test_feed',
          'slack_channel_sessions_prefix',
          'slack_channel_web_bug',
          'slack_channel_invitation',
          'slack_session_result_portal_prefix',
        ],
        photo_storage: [
          'google_storage_photos_bucket', 'photos_driver', 'photo_thumb_width', 'photo_thumb_height', 'photo_ttl',
        ],
        greetings: [
          'greetings_storage_signed_url_ttl', 'greetings_cache_ttl', 'greetings_driver',
        ],
        performance_emails: [
          'performance_email_friday_10am', 'performance_email_monday_10am',
        ],
        support_communications_configs: [
          'support_communications_configs_normal_support_email', 'support_communications_configs_normal_support_phone',
          'support_communications_configs_strict_support_email', 'support_communications_configs_strict_support_phone',
          'support_communications_configs_bloom_normal_support_email', 'support_communications_configs_bloom_normal_support_phone',
          'support_communications_configs_bloom_strict_support_email', 'support_communications_configs_bloom_strict_support_phone',
        ],
        onboarding: [
          'dpt_go_default_pt_id',
          'max_pt_number_1',
          'max_pt_number_2',
        ],
        google_storage: [
          'central_bucket_name',
        ],
        validation: [
          'first_name_min_length',
          'first_name_max_length',
          'last_name_min_length',
          'last_name_max_length',
          'username_min_length',
          'username_max_length',
          'password_min_length',
          'password_max_length',
          'username_validation_regex',
          'free_text_body_max_length',
          'patient_notes_title_max_length',
          'patient_messages_title_max_length',
          'name_unicode_validation_regex',
          'name_and_numbers_unicode_validation_regex',
          'gender_validation_regex',
          'card_number_validation_regex',
          'card_cvv_validation_regex',
        ],
        central_systems_management: [
          'csm_microservice_enabled',
        ],
        sessions: [
          'maximum_session_exercise_repeats',
        ],
        others: [], // All unmapped props should go here
      },
    };
  },
  components: {
    DynamicInput,
    FloatingButtons,
    CapacityLevels,
  },
  computed: {
    ...mapState('Config', [ 'changedConfigs', 'numberOfChanges' ]),
    buttons() {
      if (this.numberOfChanges < 1) {
        return {};
      }
      let saveText = 'Save';
      saveText += ` (${this.numberOfChanges})`;
      return {
        cancel: {
          icon: 'x-circle',
          text: 'Revert Changes',
          color: 'danger',
        },
        save: {
          icon: 'save',
          text: saveText,
          color: 'primary',
        },
      };
    },
    chunkedGroups() {
      return Object.keys(this.groups).reduce((accumulator, key, index) => {
        const group = this.groups[key];
        const obj = {};
        obj[key] = group;
        if (index % this.groupsPerRow === 0) {
          accumulator.push([ obj ]);
        } else {
          accumulator[accumulator.length - 1].push(obj);
        }

        return accumulator;
      }, []);
    },
    singleGroups() {
      return this.single_groups.filter(key => this.filterByGroup(key).length > 0);
    },
  },
  watch: {
    currentConfigs: {
      handler(values) {
        this.$store.commit('Config/addConfigChange', values);
      },
      deep: true,
    },
  },
  methods: {
    ...mapGetters('Config', [ 'getCurrentConfigs' ]),
    fillConfigs() {
      const that = this;
      this.loading = true;
      this.$store.dispatch('Config/fetchConfigs').then(() => {
        that.currentConfigs = that.getCurrentConfigs();
        this.loading = false;
      });
    },
    notInSingleGroup(name) {
      return this.single_groups.indexOf(name) !== -1;
    },
    filterByGroup(groupName) {
      const that = this;
      if (!this.currentConfigs || !this.currentConfigs.length) {
        return 'others';
      }
      if (groupName === 'others') {
        return this.currentConfigs.filter(config => {
          let existsInGroups = false;
          Object.keys(that.groups).forEach(key => {
            if (that.groups[key].includes(config.key)) {
              existsInGroups = true;
            }
          });
          return existsInGroups ? null : config;
        }).filter(config => !this.computerVisionKeysConfig.includes(config.key));
      }

      return this.currentConfigs.filter(config => that.groups[groupName].includes(config.key));
    },
    handleFloatingButtonClick(event) {
      if (event === 'cancel') {
        this.fillConfigs();
        return;
      }
      this.$store.dispatch('Config/saveConfigs');
    },
  },
  created() {
    this.fillConfigs();
  },
};
</script>
